import { LocaleCode } from '@utils/util-types';
import {
  L1s,
  L2s,
  PricingLevel,
  PricingLevels,
  StripeProductId,
} from './cas-types';

export class StripeProduct {
  id: StripeProductId;
  l1: LocaleCode;
  l2: LocaleCode;
  pricingLevel: PricingLevel;

  constructor({
    l1,
    l2,
    pricingLevel,
  }: {
    l1: LocaleCode;
    l2: LocaleCode;
    pricingLevel: PricingLevel;
  }) {
    this.id = StripeProduct.buildId({ l1, l2, pricingLevel });
    this.l1 = l1;
    this.l2 = l2;
    this.pricingLevel = pricingLevel;
  }

  static buildId({
    l1,
    l2,
    pricingLevel,
  }: {
    l1: LocaleCode;
    l2: LocaleCode;
    pricingLevel: PricingLevel;
  }): StripeProductId {
    return `${l2}-${pricingLevel}-${l1}`;
  }

  get stripeName(): string {
    // return `${this.descriptionBase}${this.descriptionQualifier}`;
    const parts = [this.descriptionBase];
    const qualifier = this.descriptionQualifier;
    if (qualifier) {
      parts.push(qualifier);
    }
    // return parts.join(' – ');
    return parts.join(' - ');
  }

  get descriptionBase(): string {
    switch (this.l2) {
      case 'es':
        return 'Jiveworld Español';
      case 'en':
        return 'Jiveworld English';
      default:
        return undefined;
    }
  }

  get descriptionQualifier(): string {
    switch (this.pricingLevel) {
      // case 'affiliate':
      case 'concession':
        switch (this.l1) {
          case 'pt':
            return 'Selecione o desconto';
          case 'es':
            return 'Selecciona el descuento';
          case 'en':
          default:
            return 'Select discount';
        }
      case 'student':
        switch (this.l1) {
          case 'pt':
            return 'Desconto para estudantes';
          case 'es':
            return 'Descuento para estudiantes';
          case 'en':
          default:
            return 'Student pricing';
        }
      case 'classroom':
        switch (this.l1) {
          case 'pt':
            return 'Desconto em sala de aula';
          case 'es':
            return 'Descuento para el aula';
          case 'en':
          default:
            return 'Classroom pricing';
        }
      case 'retail':
      default:
        return undefined;
    }
  }

  static get all(): StripeProduct[] {
    return products;
  }
}

function buildProducts(): StripeProduct[] {
  const result: StripeProduct[] = [];
  for (const l2 of L2s) {
    for (const l1 of L1s) {
      for (const pricingLevel of PricingLevels) {
        result.push(new StripeProduct({ l1, l2, pricingLevel }));
      }
    }
  }
  return result;
}

export const products: StripeProduct[] = buildProducts();

export type StripeProductLookup = {
  // eslint-disable-next-line no-unused-vars
  [id in StripeProductId]?: StripeProduct;
};

export const productLookup: StripeProductLookup = {};
products.forEach(product => {
  productLookup[product.id] = product;
});

export const getStripeProduct = ({
  l1,
  l2,
  pricingLevel,
}: {
  l1: LocaleCode;
  l2: LocaleCode;
  pricingLevel: PricingLevel;
}): StripeProduct => {
  return productLookup[StripeProduct.buildId({ l1, l2, pricingLevel })];
};
