import deepmerge from 'deepmerge';
import { config as baseConfig } from './base'; // TODO: derive this from LIVE?
import { logLevels } from '@common/log/logger-types';
import { CatalogsMap } from '@utils/util-types';

//
// staging.jiveworld.app
//
// npm start deploy.staging
//

export const config = deepmerge(baseConfig, {
  apiEnv: 'staging',
  caliServerEnv: 'staging',

  // priceIncreaseDate: 'April 30',

  authShortCodeEnabled: false,

  showUnhandledErrors: false, // turn off until more stable
  showErrorDetails: false, // show sanitized messages instead of raw detail
  showWarningAlerts: false,

  showImportAction: true, // for now
  piMenuEnabled: false, // true except beta and prod

  // gate things until at least we make sure we're no longer indexed by google
  inviteGateEnabled: false,

  crossSiteCookiesSupported: true,

  // needs to be off for staging since that will be our initial soft-launch configuration
  enableSoundbitesOrientationVideo: true,

  enableImmersiveView: false,

  // disabled via spa.vite.config.ts (matches live)
  // i18n: {
  // },

  analytics: {
    mixpanel: {
      // mixpanel project: internal (black/green)
      projectToken: '5f46a76541c8fe1dc3a65f451180b8e9',
    },
    // segmentWriteKey: 'HP0iVsUQB6F9CawQ6uVEZLD9kpcrZcJ3', // lupa-spa (testing)
    // property: jiveworld preprod, stream name: my.preprod.jiveworld.com stream
    googleAnalyticsKey: 'G-0ELVMT9KDM',
    metaPixelId: '377747195169609',
  },

  logging: {
    bufferLogger: {
      enabled: true,
      bufferActivated: false,
      consoleActivated: true,
    },
    minimumLogLevel: logLevels.info,
  },

  bugsnag: {
    apiKey: '56ed11fed6bfebab28223aa62b630786', // cali-spa - internal
    enabled: true,
  },

  sentry: {
    tracesSampleRate: 1.0, // performance metrics
    enableReplay: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
  },

  // raygun: {
  //   enabled: true,
  //   apiKey: 'OwjN0uhBt2j6i2aNJKe2A', // spa-staging
  // },

  website: {
    urls: {
      marketingSite: 'https://www2.staging.jiveworld.app',
    },
    // downloadUrls: {
    //   playStore: 'https://go.jiveworld.com/cali-android-green',
    // },
    cookieDomain: '.staging.jiveworld.app',
  },

  mailingList: {
    // except will always exclude `@jiveworld.com` email unless `+ml` also included
    autoOptInEnabled: true,
  },

  lambdaFunctions: {
    enabled: true,
  },

  player: {
    debug: false, // turns off the '~' debug mode by default
  },

  soundbites: {
    disabled: false,
    epochDate: '2023-07-10',
    // calendarCutoffDate: '2023-11-10', // calendar only visible for users who completed a soundbite prior to this date
  },

  defaultL1: 'en',
  // forcedL2: 'es',
  catalogs: {
    es: {
      defaultL1: 'en',
      en: 'lupa',
    },
    en: {
      defaultL1: 'pt',
      pt: 'english-pt',
      es: 'english-es',
    },
  } as CatalogsMap,

  // showEnEsCatalogChoice: false,

  // enableDarkModeSupport: false,

  // activePricingGenerations: {
  //   es: '2021rc1',
  //   en: '2024rc1',
  // },
});
